import React, { useState } from "react";

import LobbyFiLogo from '../assets/LobbyFiLogo.png'
import WalletButton from './WalletButton';
import WalletConnectButton from './WalletConnectButton';
import '../tailwind.css';
import { useNavigate } from 'react-router-dom';

import { AiOutlineClose, AiOutlineMenu } from 'react-icons/ai';

const Navbar = () => {

    const [nav, setNav] = useState(false);

    const handleNav = () => {
        setNav(!nav);
      };
    
    const navigate = useNavigate();
    
    const handleButtonClick = () => {
        navigate('/MyRewards');
      };

    const handleDocsClick = () => {
        window.open('https://docs.lobbyfi.xyz/', '_blank');
    };

    const handleLogoClick = () => {
        navigate('/'); // Redirect to home page
    };

    return (
        <div className="flex justify-between items-center h-24 px-2  text-[#96fdbf]">
            <div onClick={handleLogoClick} className="cursor-pointer mr-4 md:mr-24 lg:mr-56">
                <img className="h-[112px] object-cover" alt="lobbyfi logo" src={LobbyFiLogo} />
            </div>
            <div>
                <ul className='hidden md:flex'>
                    <li className='p-4'>
                        <button onClick={handleDocsClick} className=" font-header font-[number:var(--header-font-weight)] text-[#96fdbf] text-[length:var(--header-font-size)] tracking-[var(--header-letter-spacing)] leading-[var(--header-line-height)] whitespace-nowrap [font-style:var(--header-font-style)] hover:text-[#3DFF54]"
                            style={{ fontFamily: "var(--body-text-font-family)" }}>
                            [docs]
                        </button>
                    </li>
                    <li className='p-4'>
                        <button onClick={handleButtonClick} className=" font-header font-[number:var(--header-font-weight)] text-[#96fdbf] text-[length:var(--header-font-size)] tracking-[var(--header-letter-spacing)] leading-[var(--header-line-height)] whitespace-nowrap [font-style:var(--header-font-style)] hover:text-[#3DFF54]"
                            style={{ fontFamily: "var(--body-text-font-family)" }}>
                            [my rewards]
                        </button>
                    </li>
                    <li className='p-4'>
                        <WalletButton />
                    </li>
                </ul>
            </div>
            <div onClick={handleNav} className='block md:hidden'>
                {nav ? <AiOutlineClose size={20}/> : <AiOutlineMenu size={20} />}
            </div>
            <ul className={nav ? 'fixed left-0 top-0 w-[73%] h-full border-r border-r-[#96fdbf] bg-[#000000] z-10 ease-in-out duration-500' : 'ease-in-out duration-500 fixed left-[-100%]'}>
                <li className='p-4 border-b border-[#96fdbf]'>
                    <button onClick={handleDocsClick} className=" font-header font-[number:var(--header-font-weight)] text-[#96fdbf] text-[length:var(--header-font-size)] tracking-[var(--header-letter-spacing)] leading-[var(--header-line-height)] whitespace-nowrap [font-style:var(--header-font-style)] hover:text-[#3DFF54]"
                        style={{ fontFamily: "var(--body-text-font-family)" }}>
                        [docs]
                    </button>
                </li>
                <li className='p-4 border-b border-[#96fdbf]'>
                    <button onClick={handleButtonClick} className=" font-header font-[number:var(--header-font-weight)] text-[#96fdbf] text-[length:var(--header-font-size)] tracking-[var(--header-letter-spacing)] leading-[var(--header-line-height)] whitespace-nowrap [font-style:var(--header-font-style)] hover:text-[#3DFF54]"
                        style={{ fontFamily: "var(--body-text-font-family)" }}>
                        [my rewards]
                    </button>
                </li>
                <li className='p-4 border-b border-[#96fdbf]'><WalletConnectButton /></li>
            </ul>
        </div>
    );
};



export default Navbar;
