import React, { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import {
  ApolloProvider,
  ApolloClient,
  InMemoryCache,
  useQuery,
  gql,
  createHttpLink,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import Navbar from "./components/Navbar";
import { useAccount } from 'wagmi';
import { getChainId } from '@wagmi/core';
import { config } from './config';
import { Link } from "react-router-dom";
import VectorGrid from './assets/vector_grid.svg';

// Define the GraphQL query for fetching proposals from a specific space
const PROPOSALS_QUERY = gql`
query Proposals($type: Int, $status: Int, $network: Int, $address: String, $is_auction: Boolean, $page: Int, $limit: Int) {
  proposals(
    type: $type
    status: $status
    network: $network
    address: $address
    is_auction: $is_auction
    page: $page
    limit: $limit
  ) {
    id
    type
    title
    status
    network
    address
    author
    trx_hash
    start_block
    end_block
    start_timestamp
    end_timestamp
    is_auction
    received_eth
    price_direct_buy
    voted_option
    options
    options_text
    text
  }
}
`;

const httpLink = createHttpLink({
  uri: "https://lobbyfi.xyz/graphql", // http://localhost:4000/graphql https://lobbyfi.xyz/graphql
});

// const authLink = setContext((_, { headers }) => {
//   // Retrieve the API key from an environment variable or other secure storage
//   const token = process.env.REACT_APP_GRAPHQL_API_KEY;
//   return {
//     headers: {
//       ...headers,
//       authorization: token ? `Bearer ${token}` : "",
//     },
//   };
// });

const authLink = setContext((_, { headers }) => {
  // Retrieve the API key from an environment variable or other secure storage
  const apiKey = process.env.REACT_APP_GRAPHQL_API_KEY;
  return {
    headers: {
      ...headers,
      'x-api-key': apiKey || "",
    },
  };
});

const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
});

const ProposalIndex = () => {
  const currentChainId = getChainId(config);
  const [filterState, setFilterState] = useState(""); 
  const [filterType, setFilterType] = useState("");
  const [filterNetwork, setFilterNetwork] = useState("");
  const [filterAddress, setFilterAddress] = useState(""); 
  const [filterIsAuction, setFilterIsAuction] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();

  const { isConnected } = useAccount();

  const typeMapping = {
    0: "snapshot",
    1: "on-chain",
  };

  const stateMapping = {
    0: "open",
    1: "closed",
  };

  const reverseStateMapping = {
    "open": 0,
    "closed": 1,
  };

  const networkMapping = {
    421614: "Arbitrum Sepolia",
    42161: "Arbitrum One",
    324: "zkSync",
    // 169: "Manta Pacific Mainnet",
    // 10: "Optimism",
  };

  const reverseNetworkMapping = {
    "Arbitrum One": 42161,
    "Manta Pacific Mainnet": 169,
    "Arbitrum Sepolia": 421614,
    "OP Mainnet": 10,
  };

  // useEffect(() => {
  //   if (isConnected) {
  //     setFilterNetwork(currentChainId);
  //   } else {
  //     setFilterNetwork(324);
  //   }
  // }, [currentChainId]);

  useEffect(() => {
    const chainNow = currentChainId;
    
    if (isConnected) {
      if (currentChainId) {
        setFilterNetwork(chainNow);
      } else {
        setFilterNetwork(42161); // Set to default chain
      }
    } else {
      setFilterNetwork(42161); // Set to default chain
    }
  }, [currentChainId, isConnected]);

  // const reverseStateMapping = Object.fromEntries(
  //   Object.entries(stateMapping).map(([key, value]) => [value, parseInt(key)])
  // );

  const [currentPage, setCurrentPage] = useState(1);
  const ITEMS_PER_PAGE = 20;

  // Adjust the useQuery hook to use the filter state as a variable
  const { loading, error, data, fetchMore } = useQuery(PROPOSALS_QUERY, {
    variables: {
      status: filterState !== "" ? filterState : null,
      type: filterType !== "" ? filterType : null,
      network: filterNetwork || null,
      address: filterAddress || null,
      is_auction: filterIsAuction || null,
      page: currentPage,
      limit: ITEMS_PER_PAGE,
    },
    client,
    notifyOnNetworkStatusChange: true,
  });

  const handleSelect = (value) => {
    onChange(value);
    setIsOpen(false);
  };

  console.log(data);
  // Pagination control handlers
  const goToPreviousPage = () => {
    setCurrentPage(prevPage => Math.max(1, prevPage - 1));
  };

  const goToNextPage = () => {
    setCurrentPage(prevPage => prevPage + 1);
    fetchMore({
      variables: {
        page: currentPage + 1,
        limit: ITEMS_PER_PAGE,
      },
    });
  };

  if (loading)
    return (
      <div
        className="text-[#96fdbf] bg-black min-h-screen flex items-center justify-center"
        style={{
          fontFamily: "var(--body-text-font-family)",
          height: "100vh",
          width: "100%",
        }}
      >
        Loading...
      </div>
    );
  if (error)
    return (
      <div
        className="text-[#96fdbf] bg-black min-h-screen flex items-center justify-center"
        style={{
          fontFamily: "var(--body-text-font-family)",
          height: "100vh",
          width: "100%",
        }}
      >
        Error: {error.message}
      </div>
    );

  // Render the data in a simple table
  return (
    <ApolloProvider client={client}>
      <div
        className="relative bg-black min-h-screen text-[#96fdbf]"
        style={{ fontFamily: "var(--body-text-font-family)" }}
      >
        <div className="flex flex-col  items-center justify-between w-full">
          <Navbar />
        </div>
        <div className="text-xs sm:text-base w-full bg-color-[#96fdbf] font-header text-black text-center py-1 my-3" style={{ fontFamily: "var(--body-text-font-family)", backgroundColor: '#96fdbf' }}>
            Delegating season 1 is live, first $10 M delegations get x5 points. {" "} 
            <a href="https://docs.lobbyfi.xyz/governfi-summer-season-1" target="_blank" rel="noopener noreferrer" className="underline hover:text-green-800">
            [read more]
            </a>
          </div>
        <div className="px-16 py-4 sm:px-8 md:px-16 lg:px-32 xl:px-64">
        <select
            className="bg-transparent border-2 border-[#96fdbf] mb-4 hover:text-[#3DFF54] text-lg p-2 block mx-auto sm:text-left sm:mx-0"
            value={filterNetwork}
            onChange={(e) => {setFilterNetwork(parseInt(e.target.value))}
            }
          >
            <option value="">All Networks</option>
            {Object.keys(networkMapping)
              .sort((a, b) => networkMapping[a].localeCompare(networkMapping[b]))
              .map((network) => (
              <option key={network} value={network}>
                {networkMapping[network]}
              </option>
            ))}
          </select>
        </div>
        <div className="px-4 sm:px-8 md:px-16 lg:px-32 xl:px-64">
            <select
              className="bg-transparent border-2 border-[#96fdbf] mb-4 hover:text-[#3DFF54]"
              value={filterType}
              onChange={(e) => setFilterType(parseInt(e.target.value))
              }
            >
            <option value="">All Types</option>
            {Object.keys(typeMapping).map((type) => (
              <option key={type} value={type}>
                {typeMapping[type]}
              </option>
              ))}
            </select>
            <select
              className="mx-4 bg-transparent border-2 border-[#96fdbf] mb-4 hover:text-[#3DFF54]"
              value={filterState}
              onChange={(e) => {
                console.log('State:', e.target.value);
                setFilterState(parseInt(e.target.value))}
            }
            >
            <option value="">All States</option>
            {Object.keys(stateMapping).map((state) => (
              <option key={state} value={state}>
                {stateMapping[state]}
              </option>
            ))}
            </select>

          {/* Table with dashed borders */}
          <table className="table-auto w-full border-collapse border-2 border-dashed border-[#96fdbf] text-sm sm:text-base">
            <thead>
              <tr className="border-b-2 border-dashed border-[#96fdbf]">
                <th className="font-bold p-2 border-r-2 border-dashed border-[#96fdbf]">
                  Title
                </th>
                <th className="font-bold p-2 border-r-2 border-dashed border-[#96fdbf]">
                  Status
                </th>
                <th className="font-bold p-2 border-r-2 border-dashed border-[#96fdbf]">
                  Deadline
                </th>
              </tr>
            </thead>
            <tbody>
              {data && data.proposals.map(({ id, title, end_timestamp, status }) => (
                    <tr
                    key={id}
                    className="border-b-2 border-dashed border-[#96fdbf] cursor-pointer hover:text-[#3DFF54] hover:bg-gray-800"
                    onClick={() => navigate(`/proposals/${id}`)}
                  >
                    <td className="py-4 p-2 border-r-2 border-dashed border-[#96fdbf]">
                      {title}
                    </td>
                    <td
                      className={`text-center py-4 p-2 border-r-2 border-dashed border-[#96fdbf] ${
                        status === 0
                          ? "bg-green-500"
                          : status === 1
                          ? "bg-rose-500"
                          : ""
                      }`}
                    >
                      {stateMapping[status]}
                    </td>
                    <td className="text-center py-4 p-2 border-r-2 border-dashed border-[#96fdbf]">
                      {end_timestamp ? new Date(end_timestamp * 1000).toUTCString() : ""}
                    </td>
                  </tr>
                  )
                )}
            </tbody>
          </table>
          <div className="mt-4">
            <button
              className="bg-transparent border-2 border-[#96fdbf] mr-2 p-1 hover:text-[#3DFF54] disabled:opacity-50"
              onClick={goToPreviousPage}
              disabled={currentPage === 1}
            >
              Previous
            </button>
            <button
              className="bg-transparent border-2 border-[#96fdbf] p-1 hover:text-[#3DFF54] disabled:opacity-50"
              onClick={goToNextPage}
              disabled={data && data.proposals.length < ITEMS_PER_PAGE}
            >
              Next
            </button>
          </div>
        </div>
          {/* Footer */}
          <div className="flex flex-col items-end justify-center w-full mt-64">
          <div className="flex justify-between items-center w-full px-[2px] py-2 bg-black">
            {/* Text */}
            <p className="text-xs sm:text-base font-body-text text-[#96fdbf] tracking-[1.00px] leading-[20px]"
              style={{ fontFamily: 'var(--body-text-font-family)' }}>
              © 2024 LobbyFi. All rights reserved.
            </p>

            {/* Icons */}
            <div className="flex space-x-4">
              <a href="https://t.me/lobbyfichat" target="_blank" rel="noopener noreferrer" className="hover:text-[#3DFF54]">
                [telegram]
              </a>
              <a href="https://twitter.com/lobbyfinance" target="_blank" rel="noopener noreferrer" className="hover:text-[#3DFF54]">
                [twitter]
              </a>
            </div>
          </div>

          {/* Grid Component */}
          <div className="w-full  bg-black">
            <img src={VectorGrid} alt="Retro Vector Grid" className="w-full" />
          </div>
        </div>
      </div>
    </ApolloProvider>
  );
};

export default ProposalIndex;
