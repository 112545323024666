// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap);"]);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Courier+Prime&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@tailwind base;
@tailwind components;
@tailwind utilities;

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.gradient_btn {
  background: linear-gradient(
    103.91deg,
    #9b51e0 21.01%,
    rgba(48, 129, 237, 0.8) 100%
  );
}

.pink_gradient {
  position: absolute;
  top: 0%;
  left: 0%;
  width: 123px;
  height: 123px;
  border-radius: 50%;

  background: #fb37ff;
  filter: blur(200px);
}

.blue_gradient {
  position: absolute;
  bottom: 0%;
  right: 0%;
  width: 123px;
  height: 123px;
  border-radius: 50%;

  background: #18b2de;
  filter: blur(200px);
}

.gradient-border {
  background: linear-gradient(
    168.82deg,
    #fb37ff 1.7%,
    rgba(155, 111, 238, 0) 27.12%,
    rgba(123, 127, 234, 0) 61.28%,
    #1bb2de 99.52%
  );
}`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAGA,cAAc;AACd,oBAAoB;AACpB,mBAAmB;;AAEnB,gCAAgC;AAChC;;EAEE,wBAAwB;EACxB,SAAS;AACX;;AAEA,YAAY;AACZ;EACE,0BAA0B;AAC5B;;AAEA;EACE;;;;GAIC;AACH;;AAEA;EACE,kBAAkB;EAClB,OAAO;EACP,QAAQ;EACR,YAAY;EACZ,aAAa;EACb,kBAAkB;;EAElB,mBAAmB;EACnB,mBAAmB;AACrB;;AAEA;EACE,kBAAkB;EAClB,UAAU;EACV,SAAS;EACT,YAAY;EACZ,aAAa;EACb,kBAAkB;;EAElB,mBAAmB;EACnB,mBAAmB;AACrB;;AAEA;EACE;;;;;;GAMC;AACH","sourcesContent":["@import url(\"https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap\");\n@import url('https://fonts.googleapis.com/css2?family=Courier+Prime&display=swap');\n\n@tailwind base;\n@tailwind components;\n@tailwind utilities;\n\n/* Chrome, Safari, Edge, Opera */\ninput::-webkit-outer-spin-button,\ninput::-webkit-inner-spin-button {\n  -webkit-appearance: none;\n  margin: 0;\n}\n\n/* Firefox */\ninput[type=\"number\"] {\n  -moz-appearance: textfield;\n}\n\n.gradient_btn {\n  background: linear-gradient(\n    103.91deg,\n    #9b51e0 21.01%,\n    rgba(48, 129, 237, 0.8) 100%\n  );\n}\n\n.pink_gradient {\n  position: absolute;\n  top: 0%;\n  left: 0%;\n  width: 123px;\n  height: 123px;\n  border-radius: 50%;\n\n  background: #fb37ff;\n  filter: blur(200px);\n}\n\n.blue_gradient {\n  position: absolute;\n  bottom: 0%;\n  right: 0%;\n  width: 123px;\n  height: 123px;\n  border-radius: 50%;\n\n  background: #18b2de;\n  filter: blur(200px);\n}\n\n.gradient-border {\n  background: linear-gradient(\n    168.82deg,\n    #fb37ff 1.7%,\n    rgba(155, 111, 238, 0) 27.12%,\n    rgba(123, 127, 234, 0) 61.28%,\n    #1bb2de 99.52%\n  );\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
