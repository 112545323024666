import { http, createConfig } from 'wagmi'
import { arbitrum, arbitrumSepolia, gnosis, mainnet, manta, optimism, sepolia, zkSync, blast, scroll } from 'wagmi/chains'
import { injected, metaMask, safe, walletConnect } from 'wagmi/connectors'

export const config = createConfig({
  chains: [arbitrum, arbitrumSepolia, zkSync, manta, gnosis, optimism, blast, scroll],
  connectors: [
    injected(),
    walletConnect({
      projectId: 'a7ae9a87a9ce7d61d5502a9993dce861',
    }),
    // metaMask(),
    // safe(),
  ],
  transports: {
    [arbitrum.id]: http('https://arb1.arbitrum.io/rpc'),
    [arbitrumSepolia.id]: http('https://sepolia-rollup.arbitrum.io/rpc'),
    [zkSync.id]: http('https://mainnet.era.zksync.io'),
    [manta.id]: http('https://pacific-rpc.manta.network/http'),
    [gnosis.id]: http('https://rpc.gnosischain.com/'),
    [optimism.id]: http('https://mainnet.optimism.io'), 
    // [blast.id]: http('https://rpc.blast.io')
    [blast.id]: http('https://rpc.envelop.is/blast'),
    [scroll.id]: http('https://rpc.scroll.io')
  },
})



// import { DAppProvider, useSendTransaction, useEthers, Config, Goerli, Mainnet, Arbitrum, Optimism, ZkSync, Gnosis } from '@usedapp/core'

// export const ROUTER_ADDRESS = "0x390594dB0B4404957BA0383c3BAEC4472cB9aFd8";

// export const DAPP_CONFIG = {
//   networks: [Arbitrum, ZkSync],
//   readOnlyChainId: Mainnet.chainId,
//   readOnlyUrls: {
//     [Mainnet.chainId]: "https://mainnet.infura.io/v3/8c47ebe1641941448a1d6d2762e591f9",
//     [Arbitrum.chainId]: "https://arbitrum-mainnet.infura.io/v3/8c47ebe1641941448a1d6d2762e591f9",
//     [Optimism.chainId]: "https://mainnet.optimism.io",
//     [ZkSync.chainId]: "https://mainnet.era.zksync.io",
    
//   },
// };