// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Courier+Prime&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@tailwind components;
@tailwind utilities;

@layer components {
  .all-\\[unset\\] {
    all: unset;
  }
}

:root {
  --body-text-font-family: "Courier Prime", Helvetica;
  --body-text-font-size: 18px;
  --body-text-font-style: normal;
  --body-text-font-weight: 400;
  --body-text-letter-spacing: 1.8px;
  --body-text-line-height: 20px;
  --header-1-font-family: "Courier Prime", Helvetica;
  --header-1-font-size: 35px;
  --header-1-font-style: normal;
  --header-1-font-weight: 700;
  --header-1-letter-spacing: 3.5px;
  --header-1-line-height: 20px;
  --header-font-family: "Courier Prime", Helvetica;
  --header-font-size: 20px;
  --header-font-style: normal;
  --header-font-weight: 400;
  --header-letter-spacing: 2px;
  --header-line-height: 34px;
}`, "",{"version":3,"sources":["webpack://./src/tailwind.css"],"names":[],"mappings":"AAAA,oBAAoB;AACpB,mBAAmB;;AAInB;EACE;IACE,UAAU;EACZ;AACF;;AAEA;EACE,mDAAmD;EACnD,2BAA2B;EAC3B,8BAA8B;EAC9B,4BAA4B;EAC5B,iCAAiC;EACjC,6BAA6B;EAC7B,kDAAkD;EAClD,0BAA0B;EAC1B,6BAA6B;EAC7B,2BAA2B;EAC3B,gCAAgC;EAChC,4BAA4B;EAC5B,gDAAgD;EAChD,wBAAwB;EACxB,2BAA2B;EAC3B,yBAAyB;EACzB,4BAA4B;EAC5B,0BAA0B;AAC5B","sourcesContent":["@tailwind components;\n@tailwind utilities;\n\n@import url('https://fonts.googleapis.com/css2?family=Courier+Prime&display=swap');\n\n@layer components {\n  .all-\\[unset\\] {\n    all: unset;\n  }\n}\n\n:root {\n  --body-text-font-family: \"Courier Prime\", Helvetica;\n  --body-text-font-size: 18px;\n  --body-text-font-style: normal;\n  --body-text-font-weight: 400;\n  --body-text-letter-spacing: 1.8px;\n  --body-text-line-height: 20px;\n  --header-1-font-family: \"Courier Prime\", Helvetica;\n  --header-1-font-size: 35px;\n  --header-1-font-style: normal;\n  --header-1-font-weight: 700;\n  --header-1-letter-spacing: 3.5px;\n  --header-1-line-height: 20px;\n  --header-font-family: \"Courier Prime\", Helvetica;\n  --header-font-size: 20px;\n  --header-font-style: normal;\n  --header-font-weight: 400;\n  --header-letter-spacing: 2px;\n  --header-line-height: 34px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
