import React from "react";
import { createRoot } from "react-dom/client";
// import { DAppProvider } from "@usedapp/core";
import { WagmiProvider } from 'wagmi'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'

import App from "./App";
import { config } from "./config";

import "./index.css";

const container = document.getElementById("root");
const root = createRoot(container);

const queryClient = new QueryClient()

root.render(
  <React.StrictMode>
    {/* <DAppProvider config={DAPP_CONFIG}> */}
    <WagmiProvider config={config}>
      <QueryClientProvider client={queryClient}>
        <App />
      </QueryClientProvider>
    </WagmiProvider>
    {/* </DAppProvider> */}
  </React.StrictMode>
);



// import React from "react";
// import ReactDOM from "react-dom";
// import { DAppProvider } from "@usedapp/core";

// import App from "./App";
// import { DAPP_CONFIG } from "./config";

// import "./index.css";

// ReactDOM.render(
//   <React.StrictMode>
//     <DAppProvider config={DAPP_CONFIG}>
//       <App />
//     </DAppProvider>
//   </React.StrictMode>,
//   document.getElementById("root")
// );