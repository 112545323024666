import React from 'react';
import styled, { keyframes } from 'styled-components';

const typing = keyframes`
  0% { width: 0; border-color: transparent; }
  10% { border-color: orange; }
  50% { border-color: orange; }
  100% { width: 100%; border-color: orange; }
`;

const fadeOut = keyframes`
  0% { border-color: orange; }
  10% { border-color: orange; }
  50% { border-color: orange; }
  90% { border-color: orange; }
  100% { border-color: transparent; }
`;

const TypingText = styled.h1`
  overflow: hidden;
  white-space: nowrap;
  border-right: .15em solid transparent; // Set initial border color to transparent
  width: 0;
  animation: ${typing} 4s steps(50, end) forwards, ${fadeOut} 3s ease-out forwards;;
  animation-delay: ${props => props.delay || '0s'}, ${props => props.delay ? `calc(${props.delay} + 2s)` : '2s'};
`;

const TypingTextComponent = ({ children, delay }) => {
  return (
    <TypingText delay={delay}>
      {children}
    </TypingText>
  );
};

export default TypingTextComponent;