import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import HomePage from './Home';
import MyRewards from './MyRewards';
import Proposals from './Proposals';
import ProposalDetail from './ProposalDetail';
import CurrentDelegationStatus from './CurrentDelegationStatus';


const App = () => {
  return (
    <Router>
      <Routes>
        <Route path="/"  element={<HomePage />} />
        <Route path="/MyRewards" element={<MyRewards />} />
        <Route path="/Proposals" element={<Proposals />} />
        <Route path="/proposals/:id" element={<ProposalDetail />} />
        <Route path="/CurrentDelegationStatus" element={<CurrentDelegationStatus />} />
        <Route path="*" element={<div>Page not found</div>} />
      </Routes>
    </Router>
  );
};

export default App;