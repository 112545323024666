import React, { useEffect, useRef, useState } from 'react';

const MatrixRain = () => {
  const canvasRef = useRef(null);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const checkMobile = () => {
      setIsMobile(window.innerWidth <= 768); // Adjust this breakpoint as needed
    };

    checkMobile();
    window.addEventListener('resize', checkMobile);

    return () => window.removeEventListener('resize', checkMobile);
  }, []);

  useEffect(() => {
    if (isMobile) return; // Don't run the effect on mobile

    const canvas = canvasRef.current;
    const ctx = canvas.getContext("2d", { alpha: false });

    let animationFrameId;
    let fontSize, columns, drops;

    const katakana = 'アァカサタナハマヤャラワガザダバパイィキシチニヒミリヰギジヂビピウゥクス';
    const latin = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    const kirill = 'АБВГДЕЁЖЗИЙКЛМНОПРСТУФХЦЧШЩЪЫЬЭЮЯ';
    const nums = '0123456789';
    const alphabet = kirill + katakana + latin + nums;

    const speed = 0.5;
    let lastTime = 0;

    function initMatrix() {
      fontSize = Math.max(10, Math.floor(canvas.width / 100)*0.5); 
      columns = Math.floor(canvas.width / fontSize);
      drops = new Array(columns).fill(0);
    }

    const resizeCanvas = () => {
      canvas.height = window.innerHeight * window.devicePixelRatio;
      canvas.width = window.innerWidth * window.devicePixelRatio;
      canvas.style.width = `${window.innerWidth}px`;
      canvas.style.height = `${window.innerHeight}px`;
      ctx.scale(window.devicePixelRatio, window.devicePixelRatio);
      initMatrix();
    };

    window.addEventListener('resize', resizeCanvas);
    resizeCanvas();

    function draw(currentTime) {
      animationFrameId = requestAnimationFrame(draw);

      if (currentTime - lastTime < 1000 / 20) return;
      lastTime = currentTime;

      ctx.fillStyle = "rgba(0, 0, 0, 0.05)";
      ctx.fillRect(0, 0, canvas.width, canvas.height);

      ctx.font = `bold ${fontSize}px "Courier New", monospace`;
      ctx.textAlign = "center";
      
      for (let i = 0; i < drops.length; i++) {
        const text = alphabet[Math.floor(Math.random() * alphabet.length)];
        const x = i * fontSize + fontSize / 2;
        const y = drops[i] * fontSize * 1.4;

        const gradient = ctx.createLinearGradient(x, y - fontSize, x, y);
        gradient.addColorStop(0, "rgba(0, 190, 70, 0)");
        gradient.addColorStop(1, "rgba(0, 190, 70, 0.3)");
        ctx.fillStyle = gradient;

        ctx.fillText(text, x, y);

        if (drops[i] * fontSize > canvas.height && Math.random() > 0.975) {
          drops[i] = 0;
        } else {
          drops[i] += speed;
        }
      }
    }

    animationFrameId = requestAnimationFrame(draw);

    return () => {
      cancelAnimationFrame(animationFrameId);
      window.removeEventListener('resize', resizeCanvas);
    };
  }, [isMobile]);

  if (isMobile) {
    return null; // Don't render anything on mobile
  }

  return (
    <canvas 
      ref={canvasRef} 
      style={{ 
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        zIndex: 0,
        pointerEvents: 'none'
      }}
    />
  );
};

export default MatrixRain;




// import React, { useEffect, useRef } from 'react';

// const MatrixRain = () => {
//   const canvasRef = useRef(null);

//   useEffect(() => {
//     const canvas = canvasRef.current;
//     const ctx = canvas.getContext("2d");

//     let animationFrameId;

//     const resizeCanvas = () => {
//       canvas.height = window.innerHeight;
//       canvas.width = window.innerWidth;
//       initMatrix();
//     };

//     window.addEventListener('resize', resizeCanvas);
//     resizeCanvas();

//     // var english = "1001010101110101010101010010101000101011101111010101010110101010101010101110000101";
//     // english = english.split("");

//     const katakana = 'アァカサタナハマヤャラワガザダバパイィキシチニヒミリヰギジヂビピウゥクスツヌフムユュルグズブヅプエェケセテネヘメレヱゲゼデベペオォコソトノホモヨョロヲゴゾドボポヴッン';
//     const latin = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
//     const kirill = 'АБВГДЕЁЖЗИЙКЛМНОПРСТУФХЦЧШЩЪЫЬЭЮЯ';
//     const nums = '0123456789';
//     const alphabet = kirill + katakana + latin + nums;

//     let fontSize, columns, drops;

//     function initMatrix() {
//       fontSize = canvas.width <= 768 ? 10 : 14; // Smaller font for mobile
//       columns = canvas.width / fontSize;
//       drops = [];
//       for (let x = 0; x < columns; x++) {
//         drops[x] = Math.random() * -100;
//       }
//     }

//     function draw() {
//       ctx.fillStyle = "rgba(0, 0, 0, 0.05)";
//       ctx.fillRect(0, 0, canvas.width, canvas.height);

//       ctx.fillStyle = "rgba(0, 255, 70, 0.35)"; // Brighter green with lower opacity
//       ctx.font = fontSize + "px monospace";
      
//       for (let i = 0; i < drops.length; i++) {
//         const text = alphabet[Math.floor(Math.random() * alphabet.length)];
//         ctx.fillText(text, i * fontSize, drops[i] * fontSize);

//         if (drops[i] * fontSize > canvas.height && Math.random() > 0.975) {
//           drops[i] = 0;
//         }
//         drops[i]++;
//       }

//       animationFrameId = requestAnimationFrame(draw);
//     }

//     draw();

//     return () => {
//       cancelAnimationFrame(animationFrameId);
//       window.removeEventListener('resize', resizeCanvas);
//     };
//   }, []);

//   return <canvas ref={canvasRef} style={{ 
//     position: 'fixed',
//     top: 0,
//     left: 0,
//     width: '100%',
//     height: '100%',
//     zIndex: 0,
//     pointerEvents: 'none'
//   }}/>;
// };

// export default MatrixRain;